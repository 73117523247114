import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './Components/ScrollToTop';

import {Route, BrowserRouter as Router} from 'react-router-dom';

ReactDOM.render(
    <Router basename={process.env.PUBLIC_URL}>
        {/* <Route render={({location}) => (
            <App location={location} />
        )} /> */}
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </Router>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
